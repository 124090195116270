import { createContext, useMemo } from 'react';
import {
  getLocalAccessToken,
  getLocalUserInfo,
  setLocalAccessToken,
  setLocalRefreshToken,
  setLocalUserInfo,
} from './util';
import { useImmerReducer } from 'use-immer';

const isAuth = getLocalAccessToken();
const user = getLocalUserInfo();

const initState = {
  loading: false,
  loadingType: {},
  isAuth: !!isAuth,
  user,
  spSelectBox: [],
  userListOptions: [],
  actionTypeOptions: [],
};

const mainReducer = (draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'loading': {
      const { status, type = {} } = payload;
      draft.loading = status;
      draft.loadingType = type;
      break;
    }
    case 'login': {
      const { accessToken, refreshAccessToken, name, roleId, id, email } =
        payload;
      const user = { name, roleId, id, email };

      setLocalAccessToken(accessToken);
      setLocalRefreshToken(refreshAccessToken);
      setLocalUserInfo(JSON.stringify(user));
      draft.isAuth = true;
      draft.user = user;
      break;
    }
    case 'logout': {
      localStorage.clear();
      window.location.href = '/auth';
      break;
    }
    case 'setSelectOption': {
      const { key, data } = payload;
      draft[key] = data;
      break;
    }
    default: {
      throw Error(`Unknown action: ${type}`);
    }
  }
};

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(mainReducer, initState);
  const appStateValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppContext.Provider value={appStateValue}>{children}</AppContext.Provider>
  );
};
