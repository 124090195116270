import { useContext, useState } from 'react';
import { AppContext } from '../common';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const Pagination = ({ index, total, handlePages }) => {
  const { state } = useContext(AppContext);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const handlePage = (index) => {
    if (state.loading) return;
    handlePages(index);
  };

  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^\d]/g, '');
    setInputValue(newValue);
  };

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which;
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Delete',
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Home',
      'End',
    ];
    if (!allowedKeys.includes(event.key) && !allowedKeys.includes(event.code)) {
      event.preventDefault();
    }

    if (state.loading || keyCode !== 13) return;
    const val = inputValue * 1;
    const num = val > total ? total : val;
    handlePages(num);
  };

  const items = () => {
    if (total <= 2) return;
    let arr = index === 1 || index === total ? [] : [index];

    for (let i = 1; i < 5; i++) {
      let prev = arr.length > 0 ? arr[0] - 1 : index - 1;
      let next = arr.length > 0 ? arr[arr.length - 1] + 1 : index + 1;

      if (i % 2 === 1) {
        if (prev > 1) {
          arr = [prev, ...arr];
        } else if (next < total) {
          arr = [...arr, next];
        }
      } else {
        if (next < total) {
          arr = [...arr, next];
        } else if (prev > 1) {
          arr = [prev, ...arr];
        }
      }
    }

    return arr.map((item) => (
      <div className="item" key={item}>
        <div
          className={`page ${item === index ? 'current' : ''}`}
          onClick={() => handlePage(item)}
        >
          {item}
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="pagination-section">
        <div className="content">
          {index > 1 && (
            <div className="item">
              <div className="page" onClick={() => handlePage(index - 1)}>
                &lt;
              </div>
            </div>
          )}
          <div className="item">
            <div
              className={`page ${index === 1 ? 'current' : ''}`}
              onClick={() => handlePage(1)}
            >
              1
            </div>
          </div>
          {index > 4 && (
            <div className="item">
              <div className="page point">...</div>
            </div>
          )}
          {items()}
          {total - index > 3 && (
            <div className="item">
              <div className="page point">...</div>
            </div>
          )}
          {total > 1 && (
            <div className="item">
              <div
                className={`page ${index === total ? 'current' : ''}`}
                onClick={() => handlePage(total)}
              >
                {total}
              </div>
            </div>
          )}
          {total > index && (
            <div className="item">
              <div className="page" onClick={() => handlePage(index + 1)}>
                &gt;
              </div>
            </div>
          )}
          <div className="item">{t('Jump to')}</div>
          <div className="item">
            <Input
              className="input page-input"
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="item">{t('Page')}</div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
