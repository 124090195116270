import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const accessToken = 'access_token';
export const getLocalAccessToken = () => {
  return localStorage.getItem(accessToken) || null;
};

export const setLocalAccessToken = (data) => {
  return localStorage.setItem(accessToken, data);
};

const refreshToken = 'refresh_token';
export const getLocalRefreshToken = () => {
  return localStorage.getItem(refreshToken) || null;
};

export const setLocalRefreshToken = (data) => {
  return localStorage.setItem(refreshToken, data);
};

const userInfo = 'user_info';
export const getLocalUserInfo = () => {
  const data = localStorage.getItem(userInfo);
  return data ? JSON.parse(data) : {};
};

export const setLocalUserInfo = (data) => {
  return localStorage.setItem(userInfo, data);
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
