import { useContext, useEffect, useState } from 'react';
import { Filters, Pagination, Toast } from '../components';
import { getUserList, getActionTypeList, getLogList } from '../api';
import { AppContext } from '../common';
import { useTranslation } from 'react-i18next';

let isLoad = false;

const LogUser = () => {
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const [toastData, setToastData] = useState({});
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [queryModel, setQueryModel] = useState({
    actionType: '',
    operatorUserId: '',
    startDate: '',
    endDate: '',
  });

  const filters = [
    {
      type: 'select',
      key: 'actionType',
      placeholder: t('Select Type'),
      options: state.actionTypeOptions,
    },
    {
      type: 'select',
      key: 'operatorUserId',
      placeholder: t('Select Operator'),
      options: state.userListOptions,
    },
    {
      type: 'rangePicker',
      key: 'rangePicker',
    },
  ];

  const dataTh = [
    { title: t('Sort'), key: 'number', style: { whiteSpace: 'nowrap' } },
    { title: t('Type'), key: 'actionType', style: { width: 130 } },
    { title: t('Details'), key: 'details', className: 'word-break' },
    { title: t('Operator User'), key: 'updateUserName', style: { width: 130 } },
    { title: t('Operating Time'), key: 'updateTime', style: { width: 180 } },
  ];

  const handleFilter = (data) => {
    setQueryModel(data);
    setPageIndex(1);
  };

  const handleGetList = async () => {
    if (isLoad) return;
    let _queryModel = {};
    Object.keys(queryModel).forEach((key) => {
      _queryModel[`QueryModel.${key}`] = queryModel[key];
    });
    const data = {
      pageIndex,
      pageCount: 20,
      ..._queryModel,
    };

    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await Promise.all([
      getLogList(data),
      state.userListOptions.length === 0 && getUserList(),
      state.actionTypeOptions.length === 0 && getActionTypeList(),
    ]);
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });

    const [logList, userList, actionTypeList] = res;

    if (!logList)
      return setToastData({
        type: 'error',
        content: t('Failed to get data'),
      });

    const { entities, totalPages } = logList;
    setList(entities);
    setTotalPages(totalPages);

    if (userList) {
      const userListOptions =
        userList?.map((item) => ({
          value: item.id,
          label: item.userName,
        })) || [];

      dispatch({
        type: 'setSelectOption',
        payload: { key: 'userListOptions', data: userListOptions },
      });
    }

    if (actionTypeList) {
      const actionTypeOptions =
        actionTypeList?.map((item) => ({
          value: item.actionType,
          label: item.actionTypeName,
        })) || [];

      dispatch({
        type: 'setSelectOption',
        payload: { key: 'actionTypeOptions', data: actionTypeOptions },
      });
    }
  };

  useEffect(() => {
    handleGetList();
  }, [pageIndex, queryModel]);

  return (
    <>
      <Filters
        list={filters}
        initValue={queryModel}
        handleFilter={handleFilter}
      />
      <div className="table-section">
        <div className="th">
          {dataTh.map((data) => {
            const { style, key, title } = data;
            return (
              <div className="td" style={style} key={key}>
                {title}
              </div>
            );
          })}
        </div>
        {list.map((item, index) => (
          <div className="tr" key={index}>
            {dataTh.map((data) => {
              const { key, className } = data;
              return (
                <div className={`td ${className}`} key={key}>
                  {key === 'number' ? index + 1 : item[key]}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <Pagination
        index={pageIndex}
        total={totalPages}
        handlePages={(index) => setPageIndex(index)}
      />
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default LogUser;
