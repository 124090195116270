import logo from './../images/logo.png';
import avator from './../images/avator.svg';
import { useContext, useState } from 'react';
import { AppContext } from '../common';
import { authLogout } from '../api';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { state, dispatch } = useContext(AppContext);
  const [visiable, setVisiable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleFocus = (flag) => {
    setVisiable(flag);
  };

  const handleLogout = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await authLogout();
    setIsLoading(false);
    dispatch({ type: 'logout' });
  };

  return (
    <div className="header-region">
      <div className="logo-section">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div
        className="user-info"
        onMouseEnter={() => handleFocus(true)}
        onMouseLeave={() => handleFocus(false)}
      >
        <img src={avator} alt="avator" className="avator" />
        <div className="info">
          <span>{state.user.name}</span>
          {/* <span>{state.user.email}</span> */}
        </div>
        <div className="icon-arrow-down"></div>
        {visiable && (
          <div className="dropdown-section">
            <div className="item icon-logout" onClick={handleLogout}>
              {t('Logout')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
