import { useContext, useEffect, useState } from 'react';
import lock from './../images/lock.svg';
import { AppContext } from '../common';
import { authLogout } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const Exception = () => {
  const { state, dispatch } = useContext(AppContext);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const staticText = {
    403: {
      title: t('Access Denied'),
      description: t(
        'Sorry, you do not have permission to access! Please contact the site administrator.',
      ),
    },
    404: {
      title: t('Page Not Found'),
      description: t('Sorry, the page you visited does not exist.'),
    },
  };

  // logout
  const handleBack = async () => {
    if (isLoading) return;
    if (pathname !== '/403' && state.isAuth) return navigate('/');
    if (state.isAuth) {
      setIsLoading(true);
      await authLogout();
      setIsLoading(false);
    }
    dispatch({ type: 'logout' });
  };

  useEffect(() => {
    if (pathname === '/403') {
      setText(staticText[403]);
    } else {
      setText(staticText[404]);
    }
  }, [pathname]);

  return (
    <div className="access-denied-region">
      <img src={lock} alt="Lock" className="pic" />
      <div className="title">{text.title}</div>
      <div className="descption">{text.description}</div>
      <Button
        type="primary"
        className="btn-primary btn-back"
        loading={isLoading}
        onClick={handleBack}
      >
        {t('Back')}
      </Button>
    </div>
  );
};

export default Exception;
