import { Outlet, useNavigate } from 'react-router-dom';
import { Header, Side, Content, Loading } from '../components';
import { useContext, useEffect } from 'react';
import { AppContext } from '../common';

const Home = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  // onLoad
  useEffect(() => {
    if (!state.isAuth) return navigate('/auth');
  }, []);

  return (
    <>
      <Header />
      <Side />
      <Content>
        <Outlet />
      </Content>
      {state.loading && <Loading />}
    </>
  );
};

export default Home;
