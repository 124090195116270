import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const Toast = ({ data = {}, handleCancel }) => {
  const { type, content } = data;
  const { t } = useTranslation();

  return (
    <Modal
      title={`${type === 'success' ? t('Success') : t('Error')}${t('Tips')}`}
      open={!!type}
      onCancel={() => handleCancel({})}
      footer={[
        <Button
          className="custom-ok-button"
          key="submit"
          type="primary"
          onClick={() => handleCancel({})}
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      <p>{content}</p>
    </Modal>
  );
};

export default Toast;
