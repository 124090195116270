import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppProvider } from './common';
import {
  Home,
  Auth,
  Exception,
  Calculation,
  Historical,
  Role,
  LogUser,
  LogExecution,
  DataImport,
  Process,
} from './pages';
import './scss/style.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <AppProvider>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Calculation />} />
              <Route path="import/:type" element={<DataImport />} />
              <Route path="historical" element={<Historical />} />
              <Route path="role" element={<Role />} />
              <Route path="process" element={<Process />} />
              <Route path="log/user" element={<LogUser />} />
              <Route path="log/execution" element={<LogExecution />} />
            </Route>
            <Route path="/auth" element={<Auth />} />
            <Route path="/user" element={<Auth />} />
            <Route path="*" element={<Exception />} />
          </Routes>
        </BrowserRouter>
      </DndProvider>
    </AppProvider>
  );
}

export default App;
