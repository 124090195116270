import { useContext, useEffect, useState } from 'react';
import { Filters, Pagination, Toast } from '../components';
import { getStoredProcedureList } from '../api';
import { AppContext } from '../common';
import { useTranslation } from 'react-i18next';

let isLoad = false;

const LogExecution = () => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const [toastData, setToastData] = useState({});
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [queryModel, setQueryModel] = useState({
    PROC_NAME: '',
    EXE_FLAG: '',
    startDate: '',
    endDate: '',
  });

  const filterList = [
    {
      type: 'input',
      key: 'PROC_NAME',
      placeholder: t('Name'),
    },
    {
      type: 'select',
      key: 'EXE_FLAG',
      placeholder: t('Select Flag'),
      options: [
        { value: '1', label: t('Success') },
        { value: '0', label: t('Failed') },
      ],
    },
    {
      type: 'rangePicker',
      key: 'rangePicker',
    },
  ];

  const dataTh = [
    { title: t('Sort'), key: 'number', style: { whiteSpace: 'nowrap' } },
    { title: t('Name'), key: 'proC_NAME' },
    { title: t('Value'), key: 'parM_VALUE' },
    { title: t('Info'), key: 'exE_INFO', style: { width: 80 } },
    { title: t('Note'), key: 'exE_NOTE' },
    { title: t('Start Date'), key: 'starT_DATE', style: { width: 180 } },
    { title: t('End Date'), key: 'enD_DATE', style: { width: 180 } },
  ];

  const handleFilter = (data) => {
    setQueryModel(data);
    setPageIndex(1);
  };

  const handleGetList = async () => {
    if (isLoad) return;
    let _queryModel = {};
    Object.keys(queryModel).forEach((key) => {
      _queryModel[`QueryModel.${key}`] = queryModel[key];
    });
    const data = {
      pageIndex,
      pageCount: 20,
      ..._queryModel,
    };

    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await getStoredProcedureList(data);
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to get data'),
      });

    const { entities, totalPages } = res;
    setList(entities);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    handleGetList();
  }, [pageIndex, queryModel]);

  return (
    <>
      <Filters
        list={filterList}
        initValue={queryModel}
        handleFilter={handleFilter}
      />
      <div className="table-section">
        <div className="th">
          {dataTh.map((data) => {
            const { style, key, title } = data;
            return (
              <div className="td" style={style} key={key}>
                {title}
              </div>
            );
          })}
        </div>
        {list.map((item, index) => (
          <div className="tr" key={index}>
            {dataTh.map((data) => {
              const { key } = data;
              let strHTML = item[key];

              switch (key) {
                case 'number':
                  strHTML = index + 1;
                  break;
                case 'exE_INFO':
                  strHTML = (
                    <span
                      className={item.exE_FLAG === '0' ? 'error' : 'success'}
                    >
                      {item[key]}
                    </span>
                  );
                  break;
                default:
                  break;
              }
              return (
                <div className="td" key={key}>
                  {strHTML}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <Pagination
        index={pageIndex}
        total={totalPages}
        handlePages={(index) => setPageIndex(index)}
      />
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default LogExecution;
