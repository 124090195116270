import axios from 'axios';
import {
  getLocalAccessToken,
  setLocalAccessToken,
  getLocalRefreshToken,
  setLocalRefreshToken,
} from '../common';
import { authRefreshToken } from './index';

const loginToAuth = () => {
  localStorage.clear();
  window.location.href = '/auth';
};

const axiosConfig = {
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
};
const request = axios.create(axiosConfig);
let isRefreshing = false;

// axios request interceptor
request.interceptors.request.use(
  (config) => {
    if (config.token) {
      config.headers = { Authorization: `Bearer ${getLocalAccessToken()}` };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// axios reponse interceptor
request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    // Refresh token
    if (status === 401 && !isRefreshing) {
      isRefreshing = true;
      try {
        const token = getLocalAccessToken();
        const refreshToken = getLocalRefreshToken();
        const res = await authRefreshToken({
          token,
          refreshToken,
        });

        if (!res) return loginToAuth();
        const { accessToken, refreshAccessToken } = res;
        isRefreshing = false;
        setLocalAccessToken(accessToken);
        setLocalRefreshToken(refreshAccessToken);
        return request(config);
      } catch {
        loginToAuth();
      }
    } else if (status === 500) {
      console.log('Network error!');
    }

    return Promise.reject(error);
  },
);

export default request;
