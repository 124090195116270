import { useContext } from 'react';
import { AppContext } from '../common';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    loadingType: { upload, large },
  } = state;

  return (
    <div className="loading-region">
      <div className={`loading-section ${upload ? 'bg-color' : ''}`}>
        <div className="loading-icon"></div>
        <div className="loading-text point">
          {upload ? t('Uploading') : t('Loading')}
        </div>
        <div className="loading-text">
          {large ? t('The file is large, please wait patiently') : ''}
        </div>
      </div>
    </div>
  );
};

export default Loading;
