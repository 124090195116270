import { useRef } from 'react';
import { ItemTypes } from '../common';
import { useDrag, useDrop } from 'react-dnd';
import { SwapOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ProcessItem = ({
  dataTh,
  dataItem,
  index,
  canDrag,
  moveItem,
  handleProcessEdit,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { index };
    },
    canDrag() {
      return canDrag;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`tr ${canDrag ? 'move' : ''}`}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {dataTh.map((th) => {
        const { key, style } = th;
        let strHTML = null;

        switch (key) {
          case 'number': {
            strHTML = index + 1;
            break;
          }
          case 'operate': {
            if (canDrag) {
              strHTML = <SwapOutlined />;
            } else if (![1, 2].includes(dataItem.status)) {
              strHTML = (
                <span
                  className="operate"
                  onClick={() => handleProcessEdit(dataItem)}
                >
                  {t('Edit')}
                </span>
              );
            }
            break;
          }
          default: {
            strHTML = dataItem[key];
            break;
          }
        }

        return (
          <div className="td" style={style} key={key}>
            {strHTML}
          </div>
        );
      })}
    </div>
  );
};

export default ProcessItem;
