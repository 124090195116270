import { useContext, useEffect, useState } from 'react';
import {
  getSpList,
  addOrUpdateSp,
  deleteSp,
  updateSpOrder,
  getSpSelectBox,
} from '../api';
import { AppContext } from '../common';
import { Button, Modal, Input, Select, Tag } from 'antd';
import { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ProcessItem, Toast } from '../components';
import update from 'immutability-helper';

let isLoad = false;

const Process = () => {
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const [toastData, setToastData] = useState({});
  const [list, setList] = useState([]);
  const [modalTipsOpen, setModalTipsOpen] = useState(false);
  const [modalTipsLoading, setModalTipsLoading] = useState(false);
  const [modalDataOpen, setModalDataOpen] = useState(false);
  const [modalDataLoading, setModalDataLoading] = useState(false);
  const [canDrag, setCanDrag] = useState(false);

  const initProcess = {
    id: {
      value: null,
    },
    label: {
      name: t('Name'),
      value: '',
      status: '',
    },
    storedProceduresName: {
      name: t('Stored Procedures Name'),
      value: '',
      status: '',
    },
    parameters: {
      name: t('Parameters'),
      value: [],
    },
  };

  const [modalData, setModalData] = useState(initProcess);

  const dataTh = [
    { title: t('Sort'), key: 'number', style: { whiteSpace: 'nowrap' } },
    { title: t('Name'), key: 'label' },
    { title: t('Stored Procedures Name'), key: 'storedProceduresName' },
    { title: t('Description'), key: 'description' },
    { title: t('Parameters'), key: 'parameters' },
    {
      title: t('Update Time'),
      key: 'updateTime',
      style: { whiteSpace: 'nowrap' },
    },
    { title: t('Operate'), key: 'operate', style: { whiteSpace: 'nowrap' } },
  ];

  const handleProcessAdd = () => {
    setModalData(initProcess);
    setModalDataOpen(true);
  };

  const handleBatchSort = async () => {
    if (!canDrag) return setCanDrag(true);

    dispatch({ type: 'loading', payload: { status: true } });
    const arr = list.map((item) => item.id);
    const res = await updateSpOrder({ ids: arr });
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to sort'),
      });
    setToastData({
      type: 'success',
      content: t('Sort successfully'),
    });
    setCanDrag(false);
    handleGetSpList();
  };

  const handleModalDataChange = (data) => {
    const { key, value } = data;
    const newData = JSON.parse(JSON.stringify(modalData));

    newData[key].value = value;
    newData[key].status = '';

    if (key === 'storedProceduresName') {
      newData.parameters.value = state.spSelectBox[value].parameters;
    }

    setModalData(newData);
  };

  const handleModalDataOk = async () => {
    const data = { ...modalData };
    let flag = false;
    let obj = {};

    Object.keys(data).forEach((key) => {
      const { value, editValue } = data[key];

      if (key === 'id') {
        if (value) obj[key] = value;
      } else if (!value && value !== 0) {
        data[key].status = 'error';
        flag = true;
      } else if (key === 'storedProceduresName') {
        obj[key] = state.spSelectBox[value]?.name || editValue;
      } else if (key === 'parameters') {
        obj[key] = value.join(',');
      } else {
        obj[key] = value;
      }
    });

    if (flag) return setModalData({ ...data });

    setModalDataLoading(true);
    const res = await addOrUpdateSp(obj);
    setModalDataLoading(false);
    setModalDataOpen(false);

    if (!res)
      return setToastData({
        type: 'error',
        content: obj.id
          ? t('Failed to edit process')
          : t('Failed to add process'),
      });
    setToastData({
      type: 'success',
      content: obj.id
        ? t('Process edited successfully')
        : t('Process added successfully'),
    });
    handleGetSpList();
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const dragItem = list[dragIndex];
    setList(
      update(list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem],
        ],
      }),
    );
  };

  const handleProcessEdit = (item) => {
    const { id, label, storedProceduresName, parameters, description } = item;
    const newData = JSON.parse(JSON.stringify(initProcess));
    console.log(item);

    newData.id.value = id;
    newData.label.value = label;
    newData.storedProceduresName.value = `${storedProceduresName} - ${description}`;
    newData.storedProceduresName.editValue = storedProceduresName;
    newData.parameters.value = parameters
      ? parameters.split(',').map((item) => item)
      : [];

    setModalData(newData);
    setModalDataOpen(true);
  };

  const handleModalTipsOk = async () => {
    setModalTipsLoading(true);
    const res = await deleteSp({ id: modalData.id.value });
    setModalTipsLoading(false);
    setModalTipsOpen(false);
    setModalDataOpen(false);

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to delete process'),
      });
    setToastData({
      type: 'success',
      content: t('Process deleted successfully'),
    });
    handleGetSpList();
  };

  const handleGetSpList = async () => {
    if (isLoad) return;

    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await Promise.all([
      getSpList(),
      state.spSelectBox.length === 0 && getSpSelectBox(),
    ]);
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });

    const [listData, resSelect] = res;
    if (!listData)
      return setToastData({
        type: 'error',
        content: t('Failed to get data'),
      });
    const selectData = resSelect || state.spSelectBox;

    if (!selectData) return setList(listData);

    const descriptionMapping = {};
    const spSelectBox = selectData.map((item, index) => {
      descriptionMapping[item.name] = item.cnName;
      return {
        ...item,
        label: `${item.name} - ${item.cnName}`,
        value: index,
      };
    });
    dispatch({
      type: 'setSelectOption',
      payload: { key: 'spSelectBox', data: spSelectBox },
    });

    const newListData = listData.map((item) => ({
      ...item,
      description: descriptionMapping[item.storedProceduresName],
    }));
    setList(newListData);
  };

  useEffect(() => {
    handleGetSpList();
  }, []);

  return (
    <>
      <div className="filter-section space-between">
        <div className="item">
          <Button
            className="btn-primary"
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleProcessAdd}
          >
            {t('Add Process')}
          </Button>
        </div>
        <div className="item">
          <Button
            className={canDrag ? 'btn-primary' : 'btn-dashed'}
            type={canDrag ? 'primary' : 'dashed'}
            icon={<SwapOutlined />}
            onClick={handleBatchSort}
          >
            {canDrag ? t('Save sort') : t('Batch sort')}
          </Button>
        </div>
      </div>
      <div className="table-section">
        <div className="th">
          {dataTh.map((data) => (
            <div className="td" style={data.style} key={data.key}>
              {data.title}
            </div>
          ))}
        </div>
        {list.map((item, index) => (
          <ProcessItem
            dataTh={dataTh}
            dataItem={item}
            index={index}
            key={item.id}
            canDrag={canDrag}
            handleProcessEdit={handleProcessEdit}
            moveItem={moveItem}
          />
        ))}
      </div>
      <Modal
        title={modalData.id.value ? t('Edit Process') : t('Add Process')}
        open={modalDataOpen}
        onCancel={() => setModalDataOpen(false)}
        width={700}
        footer={[
          <Button
            className="custom-cancel-button"
            key="back"
            onClick={() => setModalDataOpen(false)}
          >
            {t('Cancel')}
          </Button>,
          <Button
            className="custom-ok-button"
            key="submit"
            type="primary"
            loading={modalDataLoading}
            onClick={handleModalDataOk}
          >
            {t('Submit')}
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            style={{
              float: 'left',
              display: modalData.id.value ? 'inline-block' : 'none',
            }}
            onClick={() => setModalTipsOpen(true)}
          >
            {t('Delete')}
          </Button>,
        ]}
      >
        <div className="modal-table">
          {Object.keys(modalData).map((key) => {
            const { name, value, status } = modalData[key];
            if (!name) return '';
            return (
              <div className="row" key={key}>
                <div className="cell label">{name}</div>
                <div className="cell content">
                  {key === 'label' ? (
                    <Input
                      status={status}
                      value={value}
                      onChange={(event) =>
                        handleModalDataChange({
                          key,
                          value: event.target.value,
                        })
                      }
                    />
                  ) : (
                    ''
                  )}
                  {key === 'storedProceduresName' ? (
                    <Select
                      status={status}
                      showSearch
                      optionFilterProp="label"
                      value={value}
                      onChange={(value) =>
                        handleModalDataChange({ key, value })
                      }
                      style={{ width: '100%' }}
                      options={state.spSelectBox}
                    />
                  ) : (
                    ''
                  )}
                  {key === 'parameters' ? (
                    <>
                      {value?.map((item, index) => (
                        <Tag key={index} style={{ marginBottom: '8px' }}>
                          {item}
                        </Tag>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        title={t('Tips')}
        open={modalTipsOpen}
        confirmLoading={modalTipsLoading}
        onOk={handleModalTipsOk}
        onCancel={() => setModalTipsOpen(false)}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelButtonProps={{ className: 'custom-cancel-button' }}
      >
        <p>{t('Are you sure you want to delete this process?')}</p>
      </Modal>
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default Process;
