const Tips = ({ open, content, onCancel }) => {
  return (
    open && (
      <div className="tips-region">
        <div className="tips-section">
          <div className="tips-text">{content}</div>
          <div className="tips-cancal" onClick={onCancel}></div>
        </div>
      </div>
    )
  );
};

export default Tips;
