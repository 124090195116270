import request from './request';

/**
 * @description auth refresh token
 * @param {object} data
 * @returns {(object|null)} result or null
 */
export const authRefreshToken = async (data) => {
  try {
    const response = await request({
      url: '/api/auth/refresh_token',
      method: 'post',
      data,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description auth login
 * @param {object} data
 * @returns {(object|null)} content or null
 */
export const authLogin = async (data) => {
  try {
    const response = await request({
      url: '/api/auth/login',
      method: 'post',
      data,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description auth sso login
 * @param {object} data
 * @returns {(object|null)} content or null
 */
export const authSSOLogin = async (data) => {
  try {
    const response = await request({
      url: '/api/auth/ssologin',
      method: 'post',
      data,
    });

    return response.status === 200
      ? response?.data?.content?.model || '403'
      : null;
  } catch {
    return null;
  }
};

/**
 * @description auth logout
 * @returns {object|null} data or null
 */
export const authLogout = async () => {
  try {
    const response = await request({
      url: '/api/auth/logout',
      method: 'post',
    });

    return response.status === 200 ? response?.data : null;
  } catch {
    return null;
  }
};

/**
 * @description file implement
 * @param {object} data
 * @returns {object|null} object or null
 */
export const fileUpload = async (file, id) => {
  let fd = new FormData();
  fd.append('importTableId', id);
  fd.append(`file`, file);
  try {
    const response = await request({
      url: '/api/file/upload',
      method: 'post',
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      token: true,
      timeout: 1000 * 300,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description file download
 * @param {object} data
 * @returns {object|null} object or null
 */
export const fileDownload = async (id) => {
  try {
    const response = await request({
      url: '/api/file/download',
      method: 'get',
      token: true,
      params: { id },
      responseType: 'arraybuffer',
      timeout: 1000 * 100,
    });

    return response.status === 200 ? response : null;
  } catch {
    return null;
  }
};

/**
 * @description import table download
 * @param {object} data
 * @returns {object|null} object or null
 */
export const importTableDownload = async (id) => {
  try {
    const response = await request({
      url: '/api/file/importTableDownload',
      method: 'get',
      token: true,
      params: { id },
      responseType: 'arraybuffer',
      timeout: 1000 * 100,
    });

    return response.status === 200 ? response : null;
  } catch {
    return null;
  }
};

/**
 * @description delete upload file
 * @param {object} data
 * @returns {object|null} object or null
 */
export const deleteUploadFile = async (importTableId) => {
  try {
    const response = await request({
      url: '/api/file/deleteUploadFile',
      method: 'get',
      token: true,
      params: { importTableId },
    });

    return response.status === 200 ? response : null;
  } catch {
    return null;
  }
};

/**
 * @description get calculation detail
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getCalculationDetail = async () => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/detail',
      method: 'get',
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

export const nextStep = async (step) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/nextStep',
      method: 'get',
      params: { step },
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description calculate now
 * @param {object} data
 * @returns {object|null} object or null
 */
export const calculateNow = async (params) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/calculateNow',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

export const backUp = async () => {
  try {
    const response = await request({
      url: '/api/file/backup',
      method: 'get',
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description check now
 * @param {object} data
 * @returns {object|null} object or null
 */
export const checkNow = async (data) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/checkNow',
      method: 'post',
      data,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get import table list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getImportTableList = async (params) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/importTableList',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get history list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getHistoryList = async (params) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/list',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get sp list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getSpList = async (params) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/spList',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description add or update sp
 * @param {object} data
 * @returns {object|null} object or null
 */
export const addOrUpdateSp = async (data) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/addOrUpdateSp',
      method: 'post',
      data,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description delete sp
 * @param {object} data
 * @returns {object|null} object or null
 */
export const deleteSp = async (params) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/deleteSp',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description update sp order
 * @param {object} data
 * @returns {object|null} object or null
 */
export const updateSpOrder = async (data) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/UpdateSpOrder',
      method: 'post',
      data,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get sp select box
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getSpSelectBox = async (params) => {
  try {
    const response = await request({
      url: '/api/bonusCalculation/spSelectBox',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get role list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getAccountList = async (params) => {
  try {
    const response = await request({
      url: '/api/accManager/getAccountList',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get role list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const updateAccount = async (data) => {
  try {
    const response = await request({
      url: '/api/AccManager/CreateOrUpdateAccount',
      method: 'post',
      data,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get role list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getStoredProcedureList = async (params) => {
  try {
    const response = await request({
      url: '/api/log/storedProcedureList',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get log list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getLogList = async (params) => {
  try {
    const response = await request({
      url: '/api/log/list',
      method: 'get',
      params,
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get action type list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getActionTypeList = async () => {
  try {
    const response = await request({
      url: '/api/log/actionTypeList',
      method: 'get',
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};

/**
 * @description get user list
 * @param {object} data
 * @returns {object|null} object or null
 */
export const getUserList = async () => {
  try {
    const response = await request({
      url: '/api/AccManager/GetUserList',
      method: 'get',
      token: true,
    });

    return response.status === 200 ? response?.data?.content?.model : null;
  } catch {
    return null;
  }
};
