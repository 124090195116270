import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../common';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as IconHome } from './../images/icon_home.svg';
import { ReactComponent as IconHistorical } from './../images/icon_historical.svg';
import { ReactComponent as IconRole } from './../images/icon_role.svg';
import { ReactComponent as IconProcess } from './../images/icon_process.svg';
import { ReactComponent as IconLog } from './../images/icon_log.svg';
import { useTranslation } from 'react-i18next';

const Side = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menus, setMenus] = useState([]);
  const { t } = useTranslation();

  const menusList = [
    {
      title: t('Bonus calculation'),
      url: '/',
      icon: <IconHome className="icon" />,
    },
    {
      title: t('Weekly data import'),
      url: '/import/week',
    },
    {
      title: t('Monthly data import'),
      url: '/import/month',
    },
    {
      title: t('Quarterly data import'),
      url: '/import/quarter',
    },
    {
      title: t('Yearly data import'),
      url: '/import/year',
    },
    {
      title: t('Other data import'),
      url: '/import/other',
    },
    {
      title: t('Historical data'),
      url: '/historical',
      icon: <IconHistorical className="icon" />,
    },
    {
      title: t('Role management'),
      url: '/role',
      icon: <IconRole className="icon" />,
      admin: true,
    },
    {
      title: t('Process management'),
      url: '/process',
      icon: <IconProcess className="icon" />,
      admin: true,
    },
    {
      title: t('Operation log'),
      url: '/log/user',
      icon: <IconLog className="icon" />,
      noAction: true,
    },
    {
      title: t('User log'),
      url: '/log/user',
    },
    {
      title: t('Execution log'),
      url: '/log/execution',
    },
  ];

  const handleMenu = (item) => {
    navigate(item.url);
  };

  useEffect(() => {
    const isAdmin = state.user?.roleId === 1;
    let _menus = [];
    let urls = [];

    menusList.forEach((item) => {
      if (!isAdmin && item.admin) return;
      _menus = [..._menus, item];
      urls = [...urls, item.url];
    });
    setMenus(_menus);

    if (!urls.includes(pathname)) return navigate('/');
  }, []);

  return (
    <div className="side-region">
      <div className="menu">
        {menus?.map((item, index) => (
          <div
            className={`item ${
              item.url === pathname && !item.noAction ? 'active' : ''
            } ${item.icon ? '' : 'second'}`}
            key={index}
            onClick={() => handleMenu(item)}
          >
            {item.icon ? item.icon : <div className="icon"></div>}
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Side;
