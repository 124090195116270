import { useContext, useEffect, useState } from 'react';
import { Filters, Pagination, Toast } from '../components';
import { updateAccount, getAccountList } from '../api';
import { AppContext } from '../common';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

let isLoad = false;

const arrayToObject = (arr) => {
  const obj = {};
  arr.forEach((item) => {
    obj[item.value] = item.label;
  });
  return obj;
};

const Role = () => {
  const { state, dispatch } = useContext(AppContext);
  const [toastData, setToastData] = useState({});
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [queryModel, setQueryModel] = useState({
    keyword: '',
    roleId: '',
    ifEnable: '',
  });
  const [modifyData, setModifyData] = useState({});
  const { t } = useTranslation();

  const roleOptions = [
    { value: 1, label: t('Admin') },
    { value: 2, label: t('User') },
  ];

  const stateOptions = [
    { value: true, label: t('Active') },
    { value: false, label: t('Inactive') },
  ];

  const filterList = [
    {
      type: 'input',
      key: 'keyword',
      placeholder: t('User name'),
    },
    {
      type: 'select',
      key: 'roleId',
      placeholder: t('Select Role'),
      options: roleOptions,
    },
    {
      type: 'select',
      key: 'ifEnable',
      placeholder: t('Select status'),
      options: stateOptions,
    },
  ];

  const roleAndStateOptions = {
    roleId: {
      static: arrayToObject(roleOptions),
      options: roleOptions,
      style: { width: 100 },
    },
    ifEnable: {
      static: arrayToObject(stateOptions),
      options: stateOptions,
      style: { width: 100 },
    },
  };

  const dataTh = [
    { title: t('Sort'), key: 'number', style: { whiteSpace: 'nowrap' } },
    { title: t('User name'), key: 'userName' },
    // { title: 'WWID', key: 'wwid' },
    { title: t('Email'), key: 'email' },
    { title: t('Role'), key: 'roleId', style: { width: 130 } },
    { title: t('Status'), key: 'ifEnable', style: { width: 130 } },
    { title: t('Operate'), key: 'operate', style: { width: 100 } },
  ];

  const handleUpdateAccountSave = async (item) => {
    if (
      item.roleId === modifyData.roleId &&
      item.ifEnable === modifyData.ifEnable
    ) {
      setModifyData({});
      return;
    }
    if (state.loading) return;
    dispatch({ type: 'loading', payload: { status: true } });
    const res = await updateAccount(modifyData);
    dispatch({ type: 'loading', payload: { status: false } });
    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to update account'),
      });
    setToastData({
      type: 'success',
      content: t('Account updated successfully'),
    });
    handleGetAccountList();
  };

  const handleFilter = (data) => {
    setQueryModel(data);
    setPageIndex(1);
  };

  const handleGetAccountList = async () => {
    if (isLoad) return;
    let _queryModel = {};
    Object.keys(queryModel).forEach((key) => {
      _queryModel[`QueryModel.${key}`] = queryModel[key];
    });
    const data = {
      pageIndex,
      pageCount: 20,
      ..._queryModel,
    };

    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await getAccountList(data);
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });
    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to get data'),
      });
    const { entities, totalPages } = res;
    setModifyData({});
    setList(entities);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    handleGetAccountList();
  }, [pageIndex, queryModel]);

  return (
    <>
      <Filters
        list={filterList}
        initValue={queryModel}
        handleFilter={handleFilter}
      />
      <div className="table-section">
        <div className="th">
          {dataTh.map((data) => (
            <div className="td" style={data.style} key={data.key}>
              {data.title}
            </div>
          ))}
        </div>
        {list.map((item, index) => (
          <div className="tr" key={index}>
            {dataTh.map((data) => {
              const { key } = data;
              const isModify = modifyData.accountId === item.accountId;
              let strHTML = null;

              switch (key) {
                case 'number': {
                  strHTML = index + 1;
                  break;
                }
                case 'operate': {
                  strHTML = isModify ? (
                    <span
                      className="operate"
                      onClick={() => handleUpdateAccountSave(item)}
                    >
                      {t('Save')}
                    </span>
                  ) : (
                    <span
                      className="operate"
                      onClick={() =>
                        setModifyData({ ...item, userId: item.accountId })
                      }
                    >
                      {t('Edit')}
                    </span>
                  );
                  break;
                }
                case 'roleId':
                case 'ifEnable': {
                  strHTML = isModify ? (
                    <Select
                      className="select"
                      defaultValue={modifyData[key]}
                      style={roleAndStateOptions[key].style}
                      options={roleAndStateOptions[key].options}
                      onChange={(value) =>
                        setModifyData({
                          ...modifyData,
                          [key]: value,
                        })
                      }
                    />
                  ) : (
                    roleAndStateOptions[key].static[item[key]]
                  );
                  break;
                }
                default: {
                  strHTML = item[key];
                  break;
                }
              }
              return (
                <div className="td" key={key}>
                  {strHTML}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <Pagination
        index={pageIndex}
        total={totalPages}
        handlePages={(index) => setPageIndex(index)}
      />
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default Role;
