import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from './../images/logo.png';
import { useQuery, AppContext } from '../common';
import { authLogin, authSSOLogin } from '../api';
import { Loading, Toast } from '../components';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const Auth = () => {
  const { state, dispatch } = useContext(AppContext);
  const [toastData, setToastData] = useState({});
  const { pathname } = useLocation();
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [inputStatus, setInputStatus] = useState({ loginId: '', password: '' });
  const navigate = useNavigate();
  const code = useQuery().get('code') || '';
  const { t } = useTranslation();

  // Redirect to SSO
  const handleRedirectToSSO = () => {
    if (state.loading) return;
    dispatch({ type: 'loading', payload: { status: true } });
    const appId = process.env.REACT_APP_ID;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = `${window.location.origin}/auth`;
    const uri = `https://login.microsoftonline.com/${appId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&scope=openid email profile&redirect_uri=${redirectUri}`;
    window.location.href = decodeURIComponent(uri);
  };

  // Handle SSO login
  const handleSSOLogin = async () => {
    if (state.loading) return;

    dispatch({ type: 'loading', payload: { status: true } });
    const res = await authSSOLogin({ code });
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res) {
      return setToastData({ type: 'error', content: t('Login failed') });
    } else if (res === '403') {
      return navigate('/403');
    }

    dispatch({ type: 'login', payload: res });
    navigate('/');
  };

  // Handle login
  const handleLogin = async () => {
    if (state.loading) return;
    if (!loginId || !password) {
      setToastData({
        type: 'error',
        content: t('Please enter username and password'),
      });
      setInputStatus({
        loginId: loginId ? '' : 'error',
        password: password ? '' : 'error',
      });
      return;
    }

    dispatch({ type: 'loading', payload: { status: true } });
    const res = await authLogin({ loginId, password });
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({ type: 'error', content: t('Login failed') });
    dispatch({ type: 'login', payload: res });
    navigate('/');
  };

  const handleInputChange = (event, type) => {
    const value = event.target.value;
    setInputStatus({ ...inputStatus, [type]: '' });

    if (type === 'loginId') setLoginId(value);
    if (type === 'password') setPassword(value);
  };

  useEffect(() => {
    if (state.isAuth) return navigate('/');
    if (code && pathname === '/auth') handleSSOLogin();
  }, []);

  return (
    <>
      <div className="auth-region">
        <div className="pic">BONUS</div>
        <div className="container">
          <img src={logo} alt="logo" className="logo" />
          {pathname === '/auth' && (
            <Button
              type="primary"
              className="btn-primary btn-auth"
              loading={state.loading}
              onClick={handleRedirectToSSO}
            >
              {t('login')}
            </Button>
          )}
          {pathname === '/user' && (
            <Form className="auth-form" onFinish={handleLogin}>
              <Input
                prefix={<UserOutlined className="icon-auth" />}
                placeholder="Enter username"
                className="input input-auth"
                readOnly={state.loading}
                status={inputStatus.loginId}
                value={loginId}
                onChange={(event) => handleInputChange(event, 'loginId')}
              />
              <Input.Password
                prefix={<LockOutlined className="icon-auth" />}
                placeholder="Enter Password"
                className="input input-auth"
                readOnly={state.loading}
                status={inputStatus.password}
                value={password}
                onChange={(event) => handleInputChange(event, 'password')}
              />
              <Button
                type="primary"
                htmlType="submit"
                className="btn-primary btn-auth"
                loading={state.loading}
              >
                {t('login')}
              </Button>
            </Form>
          )}
        </div>
      </div>
      {code && state.loading && <Loading />}
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default Auth;
