import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Button, Select, DatePicker, ConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import locale from 'antd/locale/zh_CN';
import dayjs from 'dayjs';

dayjs.locale('zh-cn');

const { RangePicker } = DatePicker;

const Filters = ({ list, initValue, handleFilter }) => {
  const [params, setParams] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    let param = {};

    list.forEach((item) => {
      const key = item.key;

      if (key === 'rangePicker') {
        param = {
          ...param,
          startDate: initValue.startDate || '',
          endDate: initValue.endDate || '',
        };
      } else {
        param = { ...param, [key]: initValue[key] || '' };
      }
    });
    setParams(param);
  }, []);

  const items = () => {
    let arr = [];

    list.forEach((item, index) => {
      const { key, type, placeholder, style } = item;
      let str = '';

      switch (type) {
        case 'input': {
          str = (
            <div className="item" key={index}>
              <Input
                className="input"
                placeholder={placeholder}
                style={style}
                value={params[key]}
                onChange={(event) =>
                  setParams({ ...params, [key]: event.target.value })
                }
              />
            </div>
          );
          break;
        }
        case 'select': {
          str = (
            <div className="item" key={index}>
              <Select
                className="select"
                style={style}
                placeholder={placeholder}
                options={[{ value: '', label: t('All') }, ...item.options]}
                onChange={(value) => setParams({ ...params, [key]: value })}
              />
            </div>
          );
          break;
        }
        case 'rangePicker': {
          str = (
            <div className="item" key={index}>
              <ConfigProvider locale={locale}>
                <RangePicker
                  className="date-picker"
                  onChange={(value, dateString) =>
                    setParams({
                      ...params,
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </ConfigProvider>
            </div>
          );
          break;
        }
        default:
          str = '';
          break;
      }
      arr = [...arr, str];
    });
    return arr;
  };

  return (
    <>
      <Form className="filter-section" onFinish={() => handleFilter(params)}>
        {items()}
        <div className="item">
          <Button
            className="btn-primary"
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
          >
            {t('Search')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Filters;
