import { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../common';
import {
  getImportTableList,
  fileUpload,
  deleteUploadFile,
  checkNow,
  importTableDownload,
} from '../api';
import { Button, Modal, Input } from 'antd';
import { Tips, Toast } from '../components';
import jsFileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconWarning } from './../images/icon_warning.svg';
import { ReactComponent as IconDownload } from './../images/icon_download.svg';

let isLoad = false;

const DataImport = () => {
  const { state, dispatch } = useContext(AppContext);
  const [toastData, setToastData] = useState({});
  const { type } = useParams();
  const [files, setFiles] = useState([]);
  const [modalFile, setModalFile] = useState({});
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [importTableId, setImportTableId] = useState(null);

  const typeList = {
    week: { title: t('Weekly data import'), value: 1 },
    month: { title: t('Monthly data import'), value: 2 },
    quarter: { title: t('Quarterly data import'), value: 3 },
    year: { title: t('Yearly data import'), value: 4 },
    other: { title: t('Other data import'), value: 5 },
  };

  const statusList = {
    '-1': { loading: false, danger: true, buttonText: t('Abnormal') }, // Abnormal
    0: { loading: false, danger: false, buttonText: t('Check now') }, // Not start
    1: { loading: true, danger: false, buttonText: '' }, // Waiting
    2: { loading: true, danger: false, buttonText: '' }, // Checking
    3: { loading: false, danger: false, buttonText: t('Check done') }, // Done
    4: { loading: false, danger: true, buttonText: t('Check Failed') }, // Failed
  };

  const handleImportTableDownload = async (id) => {
    if (state.loading) return;
    dispatch({ type: 'loading', payload: { status: true } });
    const res = await importTableDownload(id);
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to download'),
      });
    const { headers, data } = res;
    const fileName = decodeURIComponent(
      headers['content-disposition']?.split(';')?.[1]?.replace('filename=', ''),
    );
    console.log(fileName);
    if (fileName === 'undefined')
      return setToastData({
        type: 'error',
        content: t('Failed to download'),
      });
    jsFileDownload(data, fileName.trim());
  };

  const handleFileUpload = (id) => {
    setImportTableId(id);
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file || !importTableId) return;

    const size = file.size / 1024 / 1024;

    if (size > 50) {
      setFileInputKey(fileInputKey + 1); // reset file input
      return setToastData({
        type: 'error',
        content: t('The size of the uploaded file does not exceed 50 MB'),
      });
    }

    dispatch({
      type: 'loading',
      payload: { status: true, type: { upload: true, large: size > 10 } },
    });
    const res = await fileUpload(file, importTableId);
    dispatch({ type: 'loading', payload: { status: false } });
    setFileInputKey(fileInputKey + 1); // reset file input

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to upload file'),
      });
    handleGetTableList();
  };

  const handleModalInputChange = (event, index) => {
    const { value } = event.target;
    const newParameters = [...modalFile.parameters];
    newParameters[index].value = value;
    newParameters[index].status = '';
    setModalFile({ ...modalFile, parameters: newParameters });
  };

  const handleModalDeleteOk = async () => {
    const { id } = modalFile;
    setModalConfirmLoading(true);
    const res = await deleteUploadFile(id);
    setModalConfirmLoading(false);
    setModalFile({});

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to delete file'),
      });
    handleGetTableList();
  };

  const handleModalCheckNowOk = async () => {
    const { id, parameters } = modalFile;
    let flag = false;
    let params = {};
    const newParameters = parameters.map((item) => {
      const { parameter, value } = item;
      if (!value) flag = true;
      params[parameter] = value;
      return { ...item, status: value ? '' : 'error' };
    });

    if (flag) {
      return setModalFile({ ...modalFile, parameters: newParameters });
    }

    setModalConfirmLoading(true);
    const res = await checkNow({ id, parameter: params });
    setModalConfirmLoading(false);
    setModalFile({});

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to check now'),
      });
    handleGetTableList();
  };

  const handleGetTableList = async () => {
    if (isLoad) return;
    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await getImportTableList({ type: typeList[type].value });
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to get data'),
      });
    setFiles(res);
  };

  useEffect(() => {
    handleGetTableList();
  }, [type]);

  return (
    <>
      <div className="calculation-region started">
        <div className="page-title">{typeList[type].title}</div>
        <div className="line"></div>
        <div className="calculation-section">
          <div className="file-upload-tip">
            <IconWarning className="icon" />
            {t('The size of the uploaded file does not exceed 50 MB')}
          </div>
          <div className="table data-import-download-table">
            {files.map((item, index) => {
              const {
                id,
                title,
                cnName,
                fileName,
                status,
                needCheck,
                uploadTime,
              } = item;

              return (
                <div className="row" key={index}>
                  <div className="cell p-r-25">
                    <span className="data-import-download">
                      {title}:
                      <div
                        className="button"
                        onClick={() => handleImportTableDownload(item.id)}
                      >
                        <IconDownload className="icon" />
                      </div>
                    </span>
                    <div className="description">{cnName}</div>
                  </div>
                  <div className="cell large">
                    {fileName ? (
                      <>
                        <div className="info-section">
                          <span className="name">
                            {fileName}
                            {!statusList[status].loading && (
                              <div
                                className="btn-delete"
                                onClick={() =>
                                  setModalFile({ ...item, modalType: 'delete' })
                                }
                              ></div>
                            )}
                          </span>
                        </div>
                        <div className="date-time">
                          <span>{uploadTime && `上传时间: ${uploadTime}`}</span>
                        </div>
                      </>
                    ) : (
                      <div
                        className="btn-upload"
                        onClick={() => handleFileUpload(id)}
                      >
                        {t('Upload file')}
                      </div>
                    )}
                  </div>
                  <div className="cell">
                    <Button
                      danger={statusList[status].danger}
                      disabled={!fileName}
                      loading={statusList[status].loading}
                      type="primary"
                      className="btn-primary btn-submit"
                      onClick={() =>
                        setModalFile({
                          ...item,
                          modalType: item.status === 0 ? 'check' : 'result',
                        })
                      }
                      style={{
                        visibility: needCheck ? 'visible' : 'hidden',
                      }}
                    >
                      {statusList[status].buttonText}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <input
        key={fileInputKey}
        style={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleFileChange}
      />
      {/* <div className="note">
        <div className="title">Note</div>
        <div className="text">Note item</div>
      </div> */}
      <Modal
        title={t('Tips')}
        open={modalFile?.modalType === 'check'}
        confirmLoading={modalConfirmLoading}
        onOk={handleModalCheckNowOk}
        onCancel={() => setModalFile({})}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelButtonProps={{ className: 'custom-cancel-button' }}
      >
        <p>{modalFile.title}</p>
        <div className="modal-table">
          {modalFile?.parameters?.map((item, index) => (
            <div className="row" key={index}>
              <div className="cell label">{item.parameter}: </div>
              <div className="cell content">
                <Input
                  value={item.value}
                  status={item.status}
                  onChange={(event) => handleModalInputChange(event, index)}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        title={t('Tips')}
        open={modalFile?.modalType === 'delete'}
        confirmLoading={modalConfirmLoading}
        onOk={handleModalDeleteOk}
        onCancel={() => setModalFile({})}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelButtonProps={{ className: 'custom-cancel-button' }}
      >
        <p>
          {t('Please confirm whether to delete the file')} {modalFile.fileName}
        </p>
      </Modal>
      <Toast data={toastData} handleCancel={setToastData} />
      <Tips
        open={modalFile?.modalType === 'result'}
        content={modalFile?.checkMessage}
        onCancel={() => setModalFile({})}
      />
    </>
  );
};

export default DataImport;
