import { useContext, useEffect, useState } from 'react';
import { Filters, Pagination, Toast } from '../components';
import { fileDownload, getHistoryList } from '../api';
import { AppContext } from '../common';
import jsFileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';

let isLoad = false;

const Historical = () => {
  const { state, dispatch } = useContext(AppContext);
  const [toastData, setToastData] = useState({});
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [queryModel, setQueryModel] = useState({
    keyword: '',
  });
  const { t } = useTranslation();

  const filterList = [
    {
      type: 'input',
      key: 'keyword',
      placeholder: t('Please enter keyword'),
      style: { width: 600 },
    },
  ];

  const dataTh = [
    { title: t('Sort'), key: 'number', style: { whiteSpace: 'nowrap' } },
    { title: t('Name'), key: 'name' },
    { title: t('Executor'), key: 'executor' },
    { title: t('Execution time'), key: 'executionTime' },
    { title: t('Operate'), key: 'operate', style: { width: 100 } },
  ];

  const handleFileDownload = async (id) => {
    if (state.loading) return;
    dispatch({ type: 'loading', payload: { status: true } });
    const res = await fileDownload(id);
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to download'),
      });
    const { headers, data } = res;
    const fileName = decodeURIComponent(
      headers['content-disposition']?.split(';')?.[1]?.replace('filename=', ''),
    );
    if (fileName === 'undefined')
      return setToastData({
        type: 'error',
        content: t('Failed to download'),
      });
    jsFileDownload(data, fileName.trim());
  };

  const handleFilter = (data) => {
    setQueryModel(data);
    setPageIndex(1);
  };

  const handleGetHistoryList = async () => {
    if (isLoad) return;
    let _queryModel = {};
    Object.keys(queryModel).forEach((key) => {
      _queryModel[`QueryModel.${key}`] = queryModel[key];
    });
    const data = {
      pageIndex,
      pageCount: 20,
      ..._queryModel,
    };

    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await getHistoryList(data);
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });
    if (!res) return;
    const { entities, totalPages } = res;
    setList(entities);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    handleGetHistoryList();
  }, [pageIndex, queryModel]);

  return (
    <>
      <Filters
        list={filterList}
        initValue={queryModel}
        handleFilter={handleFilter}
      />
      <div className="table-section">
        <div className="th">
          {dataTh.map((data) => (
            <div className="td" style={data.style} key={data.key}>
              {data.title}
            </div>
          ))}
        </div>
        {list.map((item, index) => (
          <div className="tr" key={index}>
            {dataTh.map((data) => {
              const { key } = data;
              let strHTML = null;

              switch (key) {
                case 'number': {
                  strHTML = index + 1;
                  break;
                }
                case 'operate': {
                  strHTML = (
                    <span
                      className="operate"
                      onClick={() => handleFileDownload(item.id)}
                    >
                      {t('Download')}
                    </span>
                  );
                  break;
                }
                default: {
                  strHTML = item[key];
                  break;
                }
              }

              return (
                <div className="td" key={key}>
                  {strHTML}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <Pagination
        index={pageIndex}
        total={totalPages}
        handlePages={(index) => setPageIndex(index)}
      />
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default Historical;
