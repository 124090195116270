import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Modal, Input } from 'antd';
import { FileFilled } from '@ant-design/icons';
import { AppContext } from '../common';
import { Toast } from '../components';
import {
  getCalculationDetail,
  calculateNow,
  nextStep,
  backUp,
  fileDownload,
} from '../api';
import jsFileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';

let isLoad = false;

const Calculation = () => {
  const { dispatch } = useContext(AppContext);
  const [toastData, setToastData] = useState({});
  const [step, setStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [process, setProcess] = useState([]);
  const [modalData, setModalData] = useState({});
  const [modalDataOpen, setModalDataOpen] = useState(false);
  const [modalCarryOutOpen, setModalCarryOutOpen] = useState(false);
  const [modalBackUpOpen, setModalBackUpOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const stepList = [
    {
      index: '01',
      title: t('Upload file'),
      description: t('And check the file content is accurate'),
    },
    {
      index: '02',
      title: t('Perform a calculation'),
      description: t(
        'Click Execute now. It is expected to finish in 15 minutes',
      ),
    },
    {
      index: '03',
      title: t('Calculation result'),
      description: t(
        'After the calculation is successful, you can view and download the settlement result',
      ),
    },
  ];

  const statusList = {
    '-1': { danger: true, text: t('Calculation failure') }, // Failure
    0: { text: t('Carry out') }, // Not start
    1: { waiting: true, text: t('In queue') }, // Waiting
    2: { loading: true, text: t('In execution') }, // Calculating
    3: { text: t('Calculate successfully') }, // Successfully
  };

  const handleModalDataOpen = (index, disabled) => {
    if (disabled) return;
    const { label, parameters, parametersValue } = process[index];
    const parametersArr = parameters?.split(',') || [];
    const parametersValueArr = parametersValue?.split(',') || [];
    const child = parametersArr.map((item, index) => ({
      name: item,
      value: parametersValueArr[index] || '',
      status: '',
    }));

    setModalData({ index, label, child });
    setModalDataOpen(true);
  };

  const handleModalDataOk = () => {
    const { index, child } = modalData;
    let arr = [];

    child.forEach((item) => {
      const { value } = item;

      arr = [...arr, value];
      if (!value) item.status = 'error';
    });

    if (arr.includes('')) return setModalData({ ...modalData, child });
    const newProcess = [...process];
    const item = { ...newProcess[index] };
    item.parametersValue = arr.join(',');
    newProcess[index] = item;
    setProcess(newProcess);
    setModalDataOpen(false);
  };

  const handleModalDataChange = ({ index, value }) => {
    const { child } = modalData;
    child[index].value = value;
    child[index].status = '';
    setModalData({ ...modalData, child });
  };

  const handleModalCarryOutOpen = (index, disabled) => {
    if (disabled) return;
    const { id, label, parametersValue } = process[index];
    setModalData({ id, label, parametersValue });
    setModalCarryOutOpen(true);
  };

  const handleModalCarryOutOk = async () => {
    const { id, parametersValue } = modalData;

    setModalConfirmLoading(true);
    const res = await calculateNow({ id, parm: parametersValue });
    setModalConfirmLoading(false);
    setModalCarryOutOpen(false);

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to carry out'),
      });
    handleGetDetail();
  };

  const handleBackUp = () => {
    const newProcess = process.filter((item) => [1, 2].includes(item.status));
    if (newProcess.length > 0)
      return setToastData({
        type: 'error',
        content: t('Data is being executed, Please back up the data later.'),
      });

    setModalBackUpOpen(true);
  };

  const handleModalBackUpOk = async () => {
    setModalConfirmLoading(true);
    const res = await backUp();
    setModalConfirmLoading(false);
    setModalBackUpOpen(false);

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to back up'),
      });
    navigate('/historical');
  };

  const handleDownload = async () => {
    if (isLoad) return;
    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await fileDownload();
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to download'),
      });
    const { headers, data } = res;
    const fileName = decodeURIComponent(
      headers['content-disposition']?.split(';')?.[1]?.replace('filename=', ''),
    );
    if (fileName === 'undefined')
      return setToastData({
        type: 'error',
        content: t('Failed to download'),
      });
    jsFileDownload(data, fileName);
  };

  const handleNextStep = async (step) => {
    dispatch({ type: 'loading', payload: { status: true } });
    const res = await nextStep(step);
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to go to the next step'),
      });
    handleGetDetail();
  };

  const handleGetDetail = async () => {
    if (isLoad) return;
    dispatch({ type: 'loading', payload: { status: true } });
    isLoad = true;
    const res = await getCalculationDetail();
    isLoad = false;
    dispatch({ type: 'loading', payload: { status: false } });

    if (!res)
      return setToastData({
        type: 'error',
        content: t('Failed to get calculation detail'),
      });
    const { step, process, result } = res;
    setStep(step);
    setProcess(process);
    setResult(result);
  };

  useEffect(() => {
    handleGetDetail();
  }, []);

  return (
    <>
      <div className={`calculation-region ${step !== 1 ? 'started' : ''}`}>
        <div className="page-title">{t('Bonus calculation')}</div>
        <div className="step-section">
          {stepList.map((item, ind) => (
            <div
              className={`item ${++ind === step ? 'active' : ''}`}
              key={item.index}
            >
              <span className="number">{item.index}</span>
              <span>
                {item.title}
                <br />
                {item.description}
              </span>
            </div>
          ))}
        </div>
        <div className="line"></div>
        {step === 1 && (
          <div className="text-center">
            <Checkbox
              className="checkbox"
              checked={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
            >
              {t('I have confirmed that the upload is complete')}
            </Checkbox>
            <Button
              disabled={!isChecked}
              type="primary"
              className="btn-primary btn-start"
              onClick={() => setStep(2)}
            >
              {t('Start calculation')}
            </Button>
          </div>
        )}
        {step === 2 && (
          <>
            <div className="calculation-section">
              <div className="table">
                {process.map((item, index) => {
                  const {
                    status,
                    label,
                    parameters,
                    parametersValue,
                    startTime,
                    endTime,
                  } = item;
                  const { loading, danger, waiting, text } =
                    statusList[status || 0];

                  return (
                    <div className="row" key={index}>
                      <div className="cell nowrap">{label}:</div>
                      <div className="cell large">
                        <input
                          type="text"
                          placeholder={
                            parameters
                              ? t('Please enter parameters')
                              : t('Parameter null')
                          }
                          className="input-text"
                          readOnly={true}
                          value={parametersValue || ''}
                          onClick={() =>
                            parameters &&
                            handleModalDataOpen(index, loading || waiting)
                          }
                        />
                        <div className="date-time">
                          <span>
                            {startTime &&
                              `${t('Execution time')}: ${startTime}`}
                          </span>
                          <span>
                            {endTime && `${t('Completion time')}: ${endTime}`}
                          </span>
                        </div>
                      </div>
                      <div className="cell">
                        <Button
                          danger={danger}
                          disabled={parameters && !parametersValue}
                          loading={loading}
                          type="primary"
                          className={`btn-primary btn-submit ${
                            waiting ? 'waiting' : ''
                          }`}
                          onClick={() =>
                            handleModalCarryOutOpen(index, loading || waiting)
                          }
                        >
                          <span className="text">{text}</span>
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              type="primary"
              className="btn-primary btn-start"
              onClick={() => handleNextStep(3)}
            >
              下一步
            </Button>
          </>
        )}
        {step === 3 && (
          <div className="calculation-section">
            <div className="result-title">{t('Calculation result')}</div>
            <div className="table">
              {result.map((item, index) => (
                <div className="row" key={index}>
                  <div className="cell large result-name">
                    <FileFilled className="icon-file" />
                    {item.name}
                  </div>
                  <div className="cell nowrap">
                    <span className="result-text">{t('Status')}: </span>
                    {item.status ? (
                      <span className="result-success">{t('Success')}</span>
                    ) : (
                      <span className="result-failed">{t('Failed')}</span>
                    )}
                  </div>
                  <div className="cell nowrap">
                    <span className="result-text">{t('Create time')}: </span>
                    {item.time}
                  </div>
                </div>
              ))}
            </div>
            <div className="result-btn-group">
              <Button
                type="primary"
                className="btn-primary gray"
                onClick={handleDownload}
              >
                {t('Download')}
              </Button>
              <Button
                type="primary"
                className="btn-primary gray"
                onClick={() => handleNextStep(2)}
              >
                {t('Recalculate')}
              </Button>
              <Button
                type="primary"
                className="btn-primary gray"
                onClick={handleBackUp}
              >
                {t('BackUp')}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        title={modalData.label}
        open={modalDataOpen}
        confirmLoading={modalConfirmLoading}
        onOk={handleModalDataOk}
        onCancel={() => setModalDataOpen(false)}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelButtonProps={{ className: 'custom-cancel-button' }}
      >
        <div className="modal-table">
          {modalData.child?.map((item, index) => {
            const { name, value, status } = item;
            return (
              <div className="row" key={index}>
                <div className="cell label">{name}</div>
                <div className="cell content">
                  <Input
                    status={status}
                    value={value}
                    onChange={(event) =>
                      handleModalDataChange({
                        index,
                        value: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        title={t('Tips')}
        open={modalCarryOutOpen}
        confirmLoading={modalConfirmLoading}
        onOk={handleModalCarryOutOk}
        onCancel={() => setModalCarryOutOpen(false)}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelButtonProps={{ className: 'custom-cancel-button' }}
      >
        <p>
          {t('Please confirm execution')}
          {modalData.label}
        </p>
      </Modal>
      <Modal
        title={t('Tips')}
        open={modalBackUpOpen}
        confirmLoading={modalConfirmLoading}
        onOk={handleModalBackUpOk}
        onCancel={() => setModalBackUpOpen(false)}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelButtonProps={{ className: 'custom-cancel-button' }}
      >
        <p>
          {t('Please confirm execution')}
          {t('BackUp')}
        </p>
      </Modal>
      <Toast data={toastData} handleCancel={setToastData} />
    </>
  );
};

export default Calculation;
